import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import es from "./es.json";
import pt from "./pt.json";
import br from "./br.json";
import fr from "./fr.json";
import ru from "./ru.json";
import de from "./de.json";
import ht from "./ht.json";
import sr from "./sr.json";
import sc from "./sc.json";
import el from "./el.json";

const resources = {
    en: {
      lang: en              
    },
    es: {
      lang: es
    },
    pt: {
      lang: pt
    },
    br: {
      lang: br
    },
    fr: {
      lang: fr
    },
    ru: {
      lang: ru
    },
    de: {
      lang: de
    },
    ht: {
      lang: ht
    },
    sr: {
      lang: sr
    },
    sc: {
      lang: sc
    },
    el: {
      lang: el
    }
};

i18n
  .use(initReactI18next) 
  .init({
    resources,
    lang: "en",     
    interpolation: {
      escapeValue: false 
    }
  });

  export default i18n;