import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { PinInput } from 'react-input-pin-code';
import { useTranslation } from "react-i18next";
import './App.css';

const App = () => {
  const {t, i18n} = useTranslation('lang');
  const [smenu, setSmenu ] = useState("leave");
  const [bgsitepos, setBgsitepos] = useState("leave");
  const swipeMenu = () => {
    setSmenu("enter");
    setBgsitepos("enter");
  }

  const bgSite = () => {
    setBgsitepos("leave");
    setSmenu("leave");
  }

  const [resp, setResp] = useState([{
    "id":-1,
    "url":"",
    "error":""
  }]);

  const [values, setValues] = useState(['', '', '','','','','','']);

  const valuesArray = values.toString();
  const valuesString = valuesArray.replace(/,/g, '');

  const valueslenght = valuesString.length;

  const langs = window.navigator.languages[0];
  const lang = langs.substring(0, 2);



  useEffect(() => {
    if(valueslenght === 8){
      axios.get('https://xone.renxo.com//wp-content/themes/renxo/pin_desktop.php?key=xb3zrVjk7n&pin=' + valuesString)
      .then(res => {
          const data_res = res.data;
          setResp(data_res);
        })
        .catch(err => console.log(err));
      }

      console.log(lang);

      switch (lang) {
        case 'en':
          return i18n.changeLanguage('en');
        case 'es':
          return i18n.changeLanguage('es');
        case 'pt':
          return i18n.changeLanguage('pt-PT');
        case 'br':
          return i18n.changeLanguage('pt-BR');
        case 'fr':
          return i18n.changeLanguage('fr');
        case 'ru':
          return i18n.changeLanguage('ru');
        case 'ht':
          return i18n.changeLanguage('ht');
        case 'de':
          return i18n.changeLanguage('de');
        case 'ht':
          return i18n.changeLanguage('ht');
        case 'sr':
          return i18n.changeLanguage('sr');  
        case 'sc':
          return i18n.changeLanguage('sc'); 
        case 'el':
          return i18n.changeLanguage('el'); 
        default:
          return i18n.changeLanguage('en');
      }
  },[values]);
  
var id = resp[0]["id"];
var cty = resp[0]["cty"];
var op = resp[0]["op"];
var tipo = resp[0]["tipo"];
var contenido = resp[0]["contenido"];
var proveedor = resp[0]["proveedor"];
var url = "https://play.xone.games/";
var categoria = resp[0]["categoria"];
var cliente = resp[0]["cliente"];
var key = 'xdpUCOKeqUHsOrQp';
var producto = "XONE";
var brand = resp[0]["brand"];
var sp = resp[0]["subscription_plan"];
var msisdn = resp[0]["msisdn"];
var cmsid = resp[0]["cms_id"];

    const gamePlay = () => {
            axios.get('https://xone.renxo.com/wp-content/themes/renxo/reporting.php?tester=false&territorio=' + cty + '&tipo=' + tipo + '&contenido=' + contenido + '&proveedor=' + proveedor + '&url=' + url + '&categoria=' + categoria + '&key=' + key + '&op=' + op + '&cliente=' + cliente + '&producto=' + producto + '&brand=' + brand + '&sp=' + sp + '&msisdn=' + msisdn + '&cms_id=' + cmsid + '&source=desktop')
            .then(res => {
              const data_res = res.data;
              console.log(data_res);
            })
            .catch(err => console.log(err));
     }

     if(id >= 0){
      gamePlay();
    }

  return(
  resp.slice(0, 1).map((game, index) => (
      <Fragment key={index}>
        <div id="mobile">
          <img src="img/pc_error.svg" alt="mobile"></img>
          <h2>{t('contenido')}</h2>
          <p>{t('chrome')}</p>
        </div>
        <div id="menu_bg">
          <div id="logo"><img className="logo" src="img/logoxone.png" alt="logo"></img></div>
          <div id="menu" onClick={swipeMenu}><img className="menu" src="img/header_menu.svg" alt="menu"></img></div>
        </div>
        
        <div id="bg_menu" onClick={bgSite} className={bgsitepos}></div>
          <div id="swipeMenu" className={smenu}>
            {  id < 0 ?
            null
             :
             <ul>
             <li><img src="img/book.svg" alt="book"></img><a href="https://wap.renxo.com/es/es/terms.do?tln=39&footer=false" target="_blank">{t('terms')}</a></li>
             <li><img src="img/book.svg" alt="book"></img><a href="https://wap.renxo.com/privacy.do?tln=39&amp;footer=false" target="_blank">{t('poly')}</a></li>
           </ul> 
              }
              <p>{t('copy')}</p>
              <p>{t('service')}</p>
          </div>
        {
          id < 0 ?
          <div id="pin">
          {
          id === -2 ?    <img className="img_top" src="img/pc.svg" alt="pc"></img>
          :
          <img className="img_top" src="img/pc.svg" alt="pc"></img>
          }
          <h2>{t('pin')}</h2>
          <PinInput
            placeholder=""
            validBorderColor="#fff"
            inputStyle={{
            backgroundColor: '#fff',
            height:'3.5rem',
            fontSize: '2rem',
            borderRadius:'10px'
            }}
            values={values}
            onChange={(value, index, values) => setValues(values)}
          />
            <p className="error">{game.error}</p>
            <p className="chrome">{t('chrome')}</p>
           </div>
        :
        <div id="iframe"><iframe title="Xone Games" src={game.url} frameBorder="0"></iframe></div>
    }
  </Fragment>
  ))
  );
}

export default App;
